import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Box, Text as BaseText } from 'Components/UI'

export const Container = styled(Box).attrs({})`
  margin-bottom: 32px;
  width: 360px;
  min-width: 360px;
  height: fit-content;
  position: sticky;
  top: 74px;

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    width: 100%;
    min-width: 100%;
  }
`

export const Content = styled(Box).attrs({})``

export const StyledTitle = styled(BaseText).attrs({
  fontSize: [1, 1, '20px'],
})`
  color: ${themeGet('colors.black')};
  text-align: left;
  letter-spacing: 0.7px;
  font-weight: bold;
`

export const GroupedServicesTitleText = styled(BaseText).attrs({
  fontSize: 14,
  mb: 3,
  px: 3,
  py: 3,
})`
  line-height: 1.4;
  background-color: #eff7ff;
  color: #00447b;
  border: 1px solid #d6eaff;
  border-radius: 2px;
`
