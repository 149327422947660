import React, { useMemo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import get from 'lodash/get'

import { _ } from 'Services/I18n'
import { SEARCH_VIEW } from 'Constants/ids'
import StorageKeys from 'Constants/storageKeys'

import { useBaseTheme, useLocalStorage, useHydrated } from 'Hooks'

import { ServiceCard, SearchViewSwitch } from 'Components/Blocks'

import { Box, Flex } from 'Components/UI'

import { Dash, StyledTitle } from '../Content/styles'
import { Container, ServicesTitleText, ServicesContainer } from './styles'

const Services = ({ merchant, services, title, ...rest }) => {
  const { bookingPanelCopy, isPortableDevice, showAvailability } =
    useBaseTheme()
  const hydrated = useHydrated()
  const [isCompact, setIsCompact] = useState(false)

  const [searchView, setSearchView] = useLocalStorage(
    StorageKeys.MERCHANT_SERVICES_VIEW,
    SEARCH_VIEW.LIST,
  )

  // Using state to resolve SSR bug where `compact` attribute
  // was not updating in the ServicesContainer component
  useEffect(() => {
    setIsCompact(searchView === SEARCH_VIEW.GRID || isPortableDevice)
  }, [])

  const handleChangeSearchView = view => {
    setIsCompact(view === SEARCH_VIEW.GRID)
    setSearchView(view)
  }

  const business = {
    name: get(merchant, 'name'),
    slug: get(merchant, 'slug'),
    token: get(merchant, 'token'),
    reviews: get(merchant, 'reviews'),
  }

  const getCategories = service => {
    const mainCategories = get(service, 'assigned_categories.category_1', [])
    const subCategories = get(service, 'assigned_categories.category_2', [])
    return subCategories.length ? subCategories : mainCategories
  }

  const renderServiceSearchCards = useMemo(
    () => (
      <ServicesContainer compact={isCompact ? 1 : 0}>
        {map(services, service => {
          const item = {
            business,
            service,
            categories: getCategories(service),
          }

          return (
            <ServiceCard
              compact={isCompact}
              key={service.id}
              service={item}
              withDescription
            />
          )
        })}
      </ServicesContainer>
    ),
    [isCompact, searchView, services],
  )

  return (
    <Container {...rest}>
      {title && (
        <Box>
          <StyledTitle pl="25px">{title}</StyledTitle>
          <Dash mb={1} />
        </Box>
      )}

      {bookingPanelCopy && (
        <ServicesTitleText>{bookingPanelCopy}</ServicesTitleText>
      )}

      <Flex
        flexDirection={showAvailability ? 'row' : 'row-reverse'}
        justifyContent="space-between"
      >
        {!isPortableDevice && (
          <Flex>
            {hydrated && (
              <SearchViewSwitch
                value={searchView}
                onChange={handleChangeSearchView}
              />
            )}
          </Flex>
        )}
      </Flex>

      {renderServiceSearchCards}
    </Container>
  )
}

Services.defaultProps = {
  merchant: null,
  services: [],
  title: _('common.services'),
}

Services.propTypes = {
  merchant: PropTypes.object,
  services: PropTypes.array,
  title: PropTypes.string,
}

export default Services
