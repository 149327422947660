import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Box } from 'Components/UI'

export const Container = styled(Box).attrs({})`
  width: 100%;
  position: relative;
`

export const WishlistToggleContainer = styled(Box).attrs({
  right: [0, 0, 0, 1],
  top: [0, 0, 0, 1],
  position: 'absolute',
})``

export const ImageHolder = styled(Box).attrs({})`
  height: 482px;
  width: 100%;
  min-width: 215px;
  position: relative;
  cursor: pointer;

  border-radius: ${themeGet('borderRadius.1')};
  overflow: hidden;

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    height: 360px;
  }

  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    height: 310px;
  }
`

export const Image = styled(Box).attrs({})`
  width: 100%;
  height: 100%;
  background: url(${({ src }) => src}) no-repeat center center / cover
    content-box;
`

export const TotalLabelContainer = styled(Box).attrs({
  right: 0,
  bottom: 0,
  position: 'absolute',
})`
  background-color: rgba(255, 255, 255, 0.8);
  padding: 4px 12px;
  border-top-left-radius: 12px;
  color: ${themeGet('colors.text')};
  font-weight: 600;
  cursor: pointer;
`
