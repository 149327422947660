import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { CONTENT_WIDTH, CONTENT_PADDING_X } from 'Constants/ids'

import { longArrowLeftGlyph } from 'Assets/Svg'
import { MapPinIcon } from 'Assets/Svg/General'

import { Flex, Box, Text, Title, Icon } from 'Components/UI'

export const WrapperContainer = styled(Flex).attrs({
  px: CONTENT_PADDING_X,
})`
  width: 100%;
  max-width: ${CONTENT_WIDTH}px;
  position: relative;
  margin: 0 auto;
  justify-content: center;
  gap: 32px;
`

export const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: black;

  max-width: ${({ fullwidth }) => (fullwidth ? '100%' : '736px')};

  @media screen and (max-width: 1200px) {
    max-width: ${({ fullwidth }) =>
      fullwidth ? '100%' : 'calc(100% - 400px)'};
  }

  @media screen and (max-width: ${themeGet('breakpoints.2')}) {
    max-width: 100vw;
    margin: 0;
  }
`

export const Responsive = styled(Flex).attrs({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  pb: 3,
})`
  width: 100%;

  ${Title} {
    text-align: center;
  }

  > svg {
    fill: ${themeGet('colors.font.primary')};
    width: 200px;
    height: auto;
  }
`

export const Content = styled(Flex).attrs({
  mb: [0, 0, 0, 16],
  flexDirection: 'column',
})`
  color: black;
  width: 100%;
`

export const MainContent = styled(Box).attrs({
  pb: [1, 1, 4],
})`
  background-color: ${themeGet('colors.white')};
  border: 1px solid ${themeGet('colors.lightGrey')};
  border-radius: ${themeGet('borderRadius.1')};

  padding: 24px;
  width: 100%;

  &:empty {
    display: none;
  }

  @media screen and (max-width: ${themeGet('breakpoints.2')}) {
    width: 100%;
    border: 0;
    padding: 0;
  }

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    padding: 24px 0;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`

export const Row = styled(Flex).attrs({
  alignItems: 'flex-end',
  mb: [0, 0, '24px', '24px'],
})`
  width: 100%;
`

export const BackIcon = styled(Icon).attrs({
  glyph: longArrowLeftGlyph,
  size: 16,
  mr: 2,
})`
  fill: currentColor;
`

export const BackButton = styled(Flex).attrs({
  mr: 3,
  display: ['none', 'none', 'flex'],
})`
  color: ${({ color }) => color || themeGet('colors.tealBlue')};
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.5px;
  white-space: nowrap;
  align-items: flex-end;
`

export const StyledTitle = styled(Text).attrs({
  fontSize: [18, 3, '30px'],
})`
  color: ${themeGet('colors.black')};
  line-height: 1;
  font-weight: 700;
  text-align: left !important;
`

export const SubTitle = styled(Text).attrs(({ mt }) => ({
  alignItems: 'center',
  fontSize: 14,
  mt: mt || [3, 3, 4],
}))`
  color: ${themeGet('colors.black')};
  letter-spacing: 0.6px;
`

export const Top = styled(Box).attrs({})`
  display: flex;
  justify-content: space-between;

  > div {
    width: auto;
  }
`

export const TopSub = styled(Flex).attrs({
  flexDirection: ['column', 'column', 'row'],
})`
  column-gap: 16px;
`

export const MapIcon = styled(Icon).attrs({
  glyph: MapPinIcon,
  size: 16,
  mr: '6px',
})`
  color: ${({ color }) => color || themeGet('colors.tealBlue')};
  margin-top: -3px;
  cursor: pointer;
`

export const MerchantLogo = styled.img`
  max-height: 60px;
  max-width: 180px;
  align-self: flex-start;

  @media screen and (max-width: ${themeGet('breakpoints.2')}) {
    display: none;
  }
`
