import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
  loadMerchant,
  loadServicesAvailability,
  loadServicesNextAvailableDate,
  widgetAuthenticate,
  setSearchDate,
} from 'Store/Actions/marketplace'

import {
  isLoadingMerchant,
  getMerchant,
  getMerchantError,
  getCategoryValue,
  getSearchDate,
  getServicesAvailability,
  getSsrMerchantPreloaded,
  getServicesNextAvailableDate,
} from 'Store/Selectors/marketplace'

import { getAuthentication } from 'Store/Selectors/app'

import Merchant from './Merchant'

const selector = createStructuredSelector({
  isLoadingMerchant,
  authentication: getAuthentication,
  merchant: getMerchant,
  merchantError: getMerchantError,
  categoryValue: getCategoryValue,
  searchDate: getSearchDate,
  servicesAvailability: getServicesAvailability,
  servicesNextAvailableDate: getServicesNextAvailableDate,
  ssrMerchantPreloaded: getSsrMerchantPreloaded,
})

const actions = {
  onLoadMerchant: loadMerchant,
  onLoadServicesAvailability: loadServicesAvailability,
  onLoadServicesNextAvailableDate: loadServicesNextAvailableDate,
  onWidgetAuthenticate: widgetAuthenticate,
  onSetSearchDate: setSearchDate,
}

export default connect(selector, actions)(Merchant)
