import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getMerchant } from 'Store/Selectors/marketplace'

import Services from './Services'

const selector = createStructuredSelector({
  merchant: getMerchant,
})

const actions = {}

export default connect(selector, actions)(Services)
