import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Box, Flex } from 'Components/UI'

const hoverCss = () => css`
  transform: none;
  transition:
    all 0.1s ease 0s,
    -webkit-transform 0.1s ease 0s;

  @media (hover: hover) {
    &:hover {
      transform: scale(1.01);
      box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 9px 2px;
      transition:
        all 0.1s ease 0s,
        -webkit-transform 0.1s ease 0s;
    }
  }
`

export const Wrapper = styled(Flex)`
  gap: 12px;
  height: 360px;

  .video-player {
    ${hoverCss}
  }
`

export const Container = styled(Flex)`
  flex: 1;
  gap: 12px;
  position: relative;
`

export const SecondaryImages = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
  width: 100%;
`

export const SecondaryImage = styled(Flex)`
  flex: 1;
`

export const ImageBg = styled(Box).attrs({})`
  width: 100%;
  height: 100%;
  background: url(${({ src }) => src}) center no-repeat;
  background-size: cover;
  border-radius: calc(${themeGet('borderRadius.1')} / 2);

  cursor: pointer;

  ${hoverCss}
`

export const WishlistToggleContainer = styled(Box).attrs({
  right: [0, 0, 0, 1],
  top: [0, 0, 0, 1],
  position: 'absolute',
})``
