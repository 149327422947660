import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import FsLightbox from 'fslightbox-react'

import { VideoPlayer, WishlistToggle } from 'Components/Blocks'

import {
  Container,
  Image,
  ImageHolder,
  TotalLabelContainer,
  WishlistToggleContainer,
} from './styles'

const MerchantSlider = ({ images, merchant, ...rest }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const videoRef = useRef(null)

  const total = images.length + (merchant.video_url ? 1 : 0)

  const handleImageClick = () => {
    setIsModalOpen(!isModalOpen)
  }

  const lightBoxSources = () => {
    const videoUrl = merchant.video_url
    const showVideoFirst = merchant.show_video_first
    const sources = [...images]

    if (videoUrl) {
      const videoSource = (
        <div style={{ width: '100vw', height: '100vh' }}>
          <VideoPlayer light={false} playing={false} url={videoUrl} />
        </div>
      )

      if (showVideoFirst) {
        sources.unshift(videoSource)
      } else {
        sources.push(videoSource)
      }
    }

    return sources
  }

  const media = () => {
    const videoUrl = merchant.video_url
    const showVideoFirst =
      videoUrl && (merchant.show_video_first || !images.length)

    if (showVideoFirst) {
      return (
        <ImageHolder key="video">
          <VideoPlayer
            className="video-player"
            playing={false}
            ref={videoRef}
            style={{ borderRadius: '4px', overflow: 'hidden' }}
            url={videoUrl}
            onClickPreview={() => {
              // Show the thumbnail again since we are not playing the video
              // when the user clicks on the video, we open the lightbox
              videoRef.current.showPreview()

              handleImageClick()
            }}
          />
        </ImageHolder>
      )
    }

    return (
      <ImageHolder onClick={handleImageClick}>
        <Image src={images[0]} />
      </ImageHolder>
    )
  }

  return (
    <>
      <Container {...rest}>
        <WishlistToggleContainer>
          <WishlistToggle token={merchant.token} />
        </WishlistToggleContainer>
        {media()}
        {total > 1 && (
          <TotalLabelContainer onClick={handleImageClick}>
            1 / {total}
          </TotalLabelContainer>
        )}
      </Container>

      <FsLightbox
        exitFullscreenOnClose
        sourceIndex={0}
        sources={lightBoxSources()}
        toggler={isModalOpen}
        type="image"
      />
    </>
  )
}

MerchantSlider.defaultProps = {
  images: [],
}

MerchantSlider.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  merchant: PropTypes.object.isRequired,
}

export default MerchantSlider
