import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Text as BaseText, Flex } from 'Components/UI'

export const Container = styled(Flex).attrs(({ top }) => ({
  flexDirection: 'column',
  mt: top || [2, 2, 48],
  pb: [4, 4, 0],
}))`
  gap: 16px;
  width: 100%;
`

export const ServicesTitleText = styled(BaseText).attrs({
  fontSize: 14,
  px: 3,
  py: 3,
})`
  line-height: 1.4;
  background-color: #eff7ff;
  color: #00447b;
  border: 1px solid #d6eaff;
  border-radius: ${themeGet('borderRadius.1')};
`

const isGridCss = ({ compact }) =>
  compact &&
  css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    width: 100%;
  `

export const ServicesContainer = styled(Flex).attrs({
  flexDirection: 'column',
})`
  width: 100%;
  gap: 16px;

  ${isGridCss};
`
